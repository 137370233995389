import React, {useState} from "react";
import {useClient, useTable} from "hooks";
import {FormGroup, Page} from "components/common";
import {Table} from "reactstrap";
import { Link } from "react-router-dom";
import useUserContext from "../../hooks/useUserContext";
import {RSCStatusButton} from "../../components";
import {FormProvider, useForm} from "react-hook-form";
import {SubmitButton} from "../../@coherent/react-hook-form";
import download from "downloadjs";

function ExportSGDButton() {
  const form = useForm();
  const client = useClient();

  async function onSubmit() {
    const resp = await client.post('organisations/export_safeguarding_declarations', null, {
      responseType: 'blob'
    });
    download(resp.data, 'sgd_export.xlsx');
  }

  return (
    <FormProvider {...form}>
      <SubmitButton onClick={form.handleSubmit(onSubmit)}>
        Export SGD Status
      </SubmitButton>
    </FormProvider>
  )
}

export const List = ({ match }) => {
  const {
    Pagination,
    ViewingRecords,
    TableContainer,
    TableSearch,
    TableDataFetch,
    data,
    AddNewButton,
    BulkDeleteButton,
    BooleanIcon,
    setFilters,
  } = useTable({
    match,
    url: "organisations",
    resource: "Club",
    filters: {
      inactive: false,
      includes: 'sgd'
    }
  });
  const { isTLG, isVP, isBoxesOfHopeStaff } = useUserContext();
  const [archived, setArchived] = useState(false);

  return (
    <Page title="Clubs">
      <TableSearch className="fa-min" />
      {isVP || isBoxesOfHopeStaff ? (
        <div className="tw-flex tw-mb-3 tw-items-center tw-flex-wrap">
          <AddNewButton />
          <FormGroup label="View Inactive" check className="ml-4 mb-1">
            <input
              type="checkbox"
              className="form-check-input"
              checked={archived}
              onChange={(e) => {
                const { checked } = e.target;
                setArchived(!!checked)
                setFilters((v) => ({
                  ...v,
                  inactive: !!checked,
                }));
              }}
            />
          </FormGroup>
          <div className="tw-mx-auto" />
          <div className="tw-flex tw-space-x-2">
            <ExportSGDButton />
            <BulkDeleteButton />
          </div>

        </div>
      ) : null}
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th select />
              <th sortBy="organisation_name">Name</th>
              <th sortBy="organisation_id">ID</th>
              <th sortBy="region">Region</th>
              <th sortBy="regional_leader__name">Church Relationship Lead</th>
              {isTLG && <th sortBy="is_boxes_of_hope" className="text-center">Is BoH?</th>}
              <th sortBy="inactive_after_year">Inactive After</th>
              <th sortBy="club_health_status">Health status</th>
              <th>RSC</th>
              <th sortBy="num_completed_safeguarding_declarations">SGD</th>
            </tr>
          </thead>
          <tbody>
            {data.results.map((i) => (
              <tr key={i.id}>
                <td select />
                <td>
                  <Link to={`${match.url}/${i.id}`}>{i.organisation_name}</Link>
                </td>
                <td>{i.organisation_id}</td>
                <td>{i.region}</td>
                <td>{i.regional_leader__name}</td>
                {isTLG && <td className="text-center">
                  <BooleanIcon test={i.is_boxes_of_hope} />
                </td>}
                <td>
                  {i.inactive_after_year}
                </td>
                <td>{i.club_health_status}</td>
                <td>
                  <RSCStatusButton outline size="sm" value={i.ready_steady_cook} />
                </td>
                <td>
                  {i.num_completed_safeguarding_declarations}/{i.num_safeguarding_declarations}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <div className="tw-flex tw-justify-between">
        <ViewingRecords />
        <Pagination />
      </div>
      <TableDataFetch />
    </Page>
  );
};

export default List;
