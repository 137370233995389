import {Link} from "react-router-dom";
import moment from "moment";
import {formatDateTime, getFormStatus} from "../../util";
import {LatestNote} from "./LatestNote";
import {NotesButton} from "./NotesButton";
import React from "react";
import {ConvertButton} from "./ConvertButton";
import {useClient} from "../../../../hooks";
import {FormGroupCheckbox} from "../../../../components/common/FormGroup";
import {Badge} from "reactstrap";

export const ApplicationRow = ({match, value, onChanged, allowConvert }) => {
  const {
    referral,
    created,
    id,
    form,
    num_notes,
    num_references_submitted,
    num_references_checked,
    latest_note,
    dbs_form,
    user__id,
    user__email,
    checks_completed,
    converted_from_user,
    last_submitted_safeguarding_declaration_year
  } = value;
  const {status, submitted, role, organisation__name, organisation_name, organisation, full_name, email} = form;
  const { submitted: dbsSubmitted } = dbs_form || {};
  const client = useClient();

  async function handleChecksCompletedChanged(e) {
    const { checked } = e.target;

    await client.patch(`applications/${id}`, {
      checks_completed: checked ? moment().format() : null
    });

    await onChanged();
  }

  return (
    <>
      <td>
        <Link to={`${match.url}/${id}`}>
          {moment(created).format("DD/MM/YYYY  HH:mm")}
        </Link>
      </td>
      <td>
        {organisation ? (
          <Link to={`/organisations/${organisation}`}>
            {organisation__name}
          </Link>
        ) : organisation_name}
      </td>
      <td>
        <ul className="tw-list-none tw-pl-0">
          <li>{full_name}</li>
          <li className="text-muted small">{email}</li>
          <li className="text-muted small">{role}</li>
        </ul>
        <br/>
      </td>
      <td>
        <ul className="tw-list-none tw-pl-0">
          <li>
            <strong>{getFormStatus(status)}</strong>
          </li>
          {converted_from_user ? (
            <li className="small text-muted">
              Referred <strong>manually</strong>
            </li>
          ) : (
            <li className="small text-muted">
              Referred at <strong>{formatDateTime(referral.created)}</strong>
            </li>
          )}

          {submitted ? (
            <>
              <li className="small text-muted">
                Application submitted at <strong>{formatDateTime(submitted)}</strong>
              </li>
              <li className="small text-muted">
                <strong>{num_references_submitted || '0'}</strong> reference(s) completed
              </li>
              {num_references_submitted ? (
                <li className="small text-muted">
                  <strong>{num_references_checked || '0'}</strong> reference(s) checked
                </li>
              ) : null}
            </>
          ): null}
          {dbsSubmitted ? (
            <li className="small text-muted">
              DBS Check submitted at <strong>{formatDateTime(dbsSubmitted)}</strong>
            </li>
          ): null}
          <li className="small text-muted">
            {last_submitted_safeguarding_declaration_year ? (
              <>
                Last completed Safeguarding Declaration: <strong>{last_submitted_safeguarding_declaration_year}</strong>
              </>
            ) : (
              'No Safeguarding Declarations completed'
            )}
          </li>
          {user__id ? (
            <li className="small text-muted">
              Converted to user <Link to={`/users/pastors/${user__id}`}>{user__email}</Link>
            </li>
          ): null}
          {allowConvert ? (
            <li>
              <hr className="my-1" />
              <FormGroupCheckbox className="small" label="Checks completed" htmlFor="checks_completed">
                <input type="checkbox"
                       defaultChecked={!!checks_completed}
                       className="form-check-input"
                       id="checks_completed"
                       onChange={handleChecksCompletedChanged}
                />
              </FormGroupCheckbox>
            </li>
          ) : (
            <li>
              {checks_completed ? (
                <Badge color="success">
                  Checks completed
                </Badge>
              ) : null}
            </li>
          )}
        </ul>
      </td>
      <td>{latest_note ? <LatestNote value={latest_note}/> : null}</td>
      <td className="tw-whitespace-no-wrap">
        <NotesButton
          onChanged={onChanged}
          application={id}
          numNotes={num_notes}
        />
        {allowConvert ? (
          <ConvertButton disabled={!!user__id} onConverted={onChanged} application={value} className="ml-2" />
        ) : null}
      </td>
    </>
  );
};