import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormGroup, SubmitButton } from "../../../@coherent/react-hook-form";

export const SafeguardingDeclarationForm = ({ data, onSubmit }) => {
  const methods = useForm({
    defaultValues: data,
  });
  const { handleSubmit, register } = methods;

  return (
    <FormProvider {...methods}>
      <p>
        I have completed the TLG Make Lunch training{" "}
        <strong>Session 1 – “We Operate Safely”</strong> and understand that I
        can access this again at any time in the video resources folder on
        Kitchen Cupboard. This session outlines the Green Flag and Red Flag approach. You can also find further details
        of these approaches in the "We operate Safely" section of the Core Team training manual.
      </p>
      <p>
        I agree to follow the safeguarding procedures outlined in the training:
      </p>
      <ul>
        <li>
          I agree to take all reasonable measures to ensure that the risks of
          harm to children’s welfare are minimised.
        </li>
        <li>
          I have read and understand the Green Flag approach that means I will
          bring consistent, positive and non-judgmental support to the children
          and families I am connected with through Make Lunch.
        </li>
        <li>
          I have read and understand the Red Flag way of describing a concern
          and will take appropriate action if/when required. I know who the
          Church Designated Safeguarding Lead is.
        </li>
        <li>
          I have read and understood TLG's Safeguarding policy which is
          available on Kitchen Cupboard (Resources/Admin/Policies and
          Statements).
        </li>
        <li>
          I agree to promote a positive safeguarding culture at my local Make
          Lunch Club.
        </li>
      </ul>
      <p>
        In line with TLG’s Safer Recruitment Policy for Make Lunch Core Team
        volunteers I will ensure that:
      </p>
      <ul>
        <li>
            I have been safely recruited
        </li>
        <li>
          I have a current DBS (England and Wales) or PVG (Scotland) or Access
          NI (Northern Ireland). Checks need to be regularly updated and TLG
          will contact the Designated Safeguarding Lead at your Church to ask
          for confirmation
        </li>
      </ul>
      <p>
        <em>
          Please note Make Lunch Core Team members are responsible for safeguarding at their club and this is why all
          Core Team must complete appropriate safeguarding training. If you have not had the opportunity to complete
          safeguarding training at church, TLG can provide access to online training via TES develop. Please speak to
          your Coordinator if you require a login to access the Child Protection course.
        </em>
      </p>
      <p>
        At the start of each year we will ask you to review and agree to this Safeguarding Declaration.
      </p>
      <p>
        <strong>
          If you are no longer volunteering as part of your Make Lunch club, please contact us at
          {' '}<a href="mailto:makelunch@tlg.org.uk">makelunch@tlg.org.uk</a> so that we can update our records.
        </strong>
      </p>
      <p className="tw-font-bold">
        <FormGroup
          htmlFor="confirmed"
          check
          name="confirmed"
          label="Click to sign Safeguarding Declaration"
        >
          <input
            id="confirmed"
            type="checkbox"
            name="confirmed"
            className="form-check-input"
            ref={register({
              required: true,
            })}
          />
        </FormGroup>
      </p>
      <div className="tw-flex tw-justify-end">
        <SubmitButton onClick={handleSubmit(onSubmit)}>Submit</SubmitButton>
      </div>
    </FormProvider>
  );
};
