import React, {useEffect, useMemo, useRef} from 'react';
import {useClient, useModal, useUserContext} from "../../hooks";
import {Alert, Button, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {
  ReadySteadyCookFields
} from "../../pages/ReadySteadyCook";
import {useQuery} from "react-query";
import {Formik, useFormikContext} from "formik";
import {debounce, isEmpty} from "lodash";

export function RSCCheckForm({ onChanged }) {
  const formik = useFormikContext();
  const { values, initialValues } = formik;
  const onChangedRef = useRef();
  onChangedRef.current = onChanged;
  const complete =
    values.ready_complete && values.steady_complete && values.cook_complete;

  useEffect(() => {
    if (values === initialValues) return;
    onChangedRef.current(values);
  }, [values, initialValues, onChangedRef])


  return (
    <>
      <ReadySteadyCookFields />
      {complete ? (
        <Alert color="success">
          <strong>Congratulations!</strong> You are ready to run your first
          session of Make Lunch!
        </Alert>
      ) : null}
    </>
  )
}

export function RSCCheck() {
  const {setIsOpen, Modal, toggle } = useModal();
  const client = useClient();
  const { setUser } = useUserContext();
  const opened = useRef(false);

  const { data: rsc } = useQuery({
    queryKey: ['@tlg/users/ready_steady_cook'],
    queryFn: () => client.get('me').get('data').get('ready_steady_cook'),
    cacheTime: 0
  });

  const isRSCEmpty = useMemo(() => {
    if (!rsc) return null;
    return isEmpty(rsc.ready) && isEmpty(rsc.steady) && isEmpty(rsc.cook);
  }, [rsc])

  async function handleChanged(values) {
    await client.post("me", {
      ready_steady_cook: values,
    });
    setUser(v => ({
      ...v,
      ready_steady_cook: values
    }));
  }

  const debouncedOnChange = useMemo(() => {
    return debounce(handleChanged, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!rsc) return null;
  if (!isRSCEmpty) return null;

  if (!opened.current) {
    setIsOpen(true);
    opened.current = true;
  }

  return (
    <>
      <Modal size="lg">
        <ModalHeader toggle={toggle}>
          Ready, Steady, Cook
        </ModalHeader>
        <ModalBody>
          <p>
            As it is the beginning of a new year your Ready Steady Cook Checklist has reset as a reminder for you to check that you have all the appropriate training and safeguarding in place to ensure you continue to operate safely this year.
          </p>
          <p>
            It is the best interests of your participants and your local church community that you take appropriate actions to prioritise safeguarding and have robust processes in place.
          </p>
          <p>
            <strong>
              Please check through the list, ticking the boxes you know are completed and making a note of areas where you may need to update training or remind volunteers of processes.
            </strong>
          </p>
          <p>
            You can update this checklist at any time by selecting the Ready Steady Cook tab in the green ribbon in preparation for your Annual Review.
          </p>
          <Formik initialValues={rsc}>
            <RSCCheckForm onChanged={debouncedOnChange} />
          </Formik>
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={() => setIsOpen(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}