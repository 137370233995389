import React, {Children, useEffect, useMemo, useState} from "react";
import { Page, Checkbox, FormGroup } from "components/common";
import { Field, Formik } from "formik";
import { useFormikContext, useOnMount, useOnUpdate } from "hooks";
import {debounce, keys} from "lodash";
import classNames from "classnames";
import { Alert } from "reactstrap";
import { useUserContext } from "../hooks";

const Title = ({ children, complete, inProgress }) => {
  return (
    <h2
      className={classNames("tw-font-medium tw-text-lg tw-mb-2", {
        "text-success": complete,
        "text-danger": !complete && !inProgress,
        "text-warning": inProgress,
      })}
    >
      {children}
      {complete ? <span className="fa fa-check-circle tw-ml-2" /> : null}
    </h2>
  );
};

const Item = ({ children, form, field }) => {
  return (
    <li>
      <FormGroup label={children} check className="tw-mb-1">
        <Checkbox
          form={form}
          field={field}
          className="form-check-input"
          onChange={(e) => {
            form.setFieldValue(field.name, e.target.checked ? true : undefined);
          }}
        />
      </FormGroup>
    </li>
  );
};

const Section = ({ title, children, field, form }) => {
  const { value, name } = field;
  const numChildren = Children.count(children);
  const numKeys = keys(value || {}).length;
  const complete = numChildren === numKeys;
  const inProgress = !complete && numKeys > 0;
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue(name + "_complete", complete, {
      shouldValidate: true
    });
  }, [complete, name, setFieldValue]);

  return (
    <div>
      <Title complete={complete} inProgress={inProgress}>
        {title}
      </Title>
      <ul className="tw-list-none tw-pl-2">{children}</ul>
    </div>
  );
};

export const ReadySteadyCookFields = () => {
  return (
    <>
      <Field name="ready" component={Section} title="READY (Safer recruitment and safeguarding)">
        <Field name="ready.core_applications" component={Item}>
          Core team (inc Coordinator) applications completed
        </Field>
        <Field name="ready.core_reference_checks" component={Item}>
          Core team (inc Coordinator) reference checks completed
        </Field>
        <Field name="ready.core_checks_confirmed" component={Item}>
          Core Team (inc Coordinator) DBS/PVG/Access NI checks completed
        </Field>
        <Field name="ready.lunchmaker_applications" component={Item}>
          Lunchmaker applications completed
        </Field>
        <Field name="ready.lunchmaker_reference_checks" component={Item}>
          Lunchmaker reference checks completed
        </Field>
        <Field name="ready.lunchmaker_checks_confirmed" component={Item}>
          Lunchmaker DBS/PVG/Access NI checks completed
        </Field>
        <Field name="ready.safeguarding_policy" component={Item}>
          Church safeguarding policy in place
        </Field>
        <Field name="ready.safeguarding_training" component={Item}>
          Level 2 safeguarding training completed by all volunteers in accordance with the interval requirements of
          churches safeguarding procedures (this should be at least every 5 years)
        </Field>
      </Field>
      <Field name="steady" component={Section} title="STEADY (Compliance)">
        <Field name="steady.registered" component={Item}>
          Registered as a Food business
        </Field>
        <Field name="steady.safer_food" component={Item}>
          Safer Food, Better Business Pack, printed and implemented
        </Field>
        <Field name="steady.allergens" component={Item}>
          Allergen processes and labelling in place
        </Field>
        <Field name="steady.public_liability" component={Item}>
          Public Liability Insurance in place
        </Field>
        <Field name="steady.risk_assessments" component={Item}>
          Risk Assessments completed
        </Field>
        <Field name="steady.fire_safety" component={Item}>
          Fire safety Procedures established
        </Field>
      </Field>
      <Field name="cook" component={Section} title="COOK (Training and preparation)">
        <Field name="cook.coordinator_training" component={Item}>
          TLG Coordinator Training completed
        </Field>
        <Field name="cook.core_training" component={Item}>
          TLG Core Team Training completed
        </Field>
        <Field name="cook.kitchen_training" component={Item}>
          Kitchen team: Food hygiene training completed by kitchen leads (refresher recommended every 3 years)
        </Field>
        <Field name="cook.allergy_training" component={Item}>
          Kitchen Team: Allergy Training completed by Kitchen Leads{' '}
          <a href="https://allergytraining.food.gov.uk/" target="_blank" rel="noopener noreferrer">
            FSA Food Allergy Training
          </a>
        </Field>
        <Field name="cook.kitchen_food_handling" component={Item}>
          Kitchen team: Basic food handling, hygiene and allergen awareness training completed by all kitchen volunteers
        </Field>
        <Field name="cook.first_aiders" component={Item}>
          Sufficient First Aiders trained
        </Field>
      </Field>
    </>
  )
}

const Form = ({ client }) => {
  const { values } = useFormikContext();
  const { setUser } = useUserContext();
  const complete =
    values.ready_complete && values.steady_complete && values.cook_complete;

  /**
   * If a section is completed, it will change values twice (to update the _completed field). So debounce the saving of
   * ready steady cook so that it doesn't POST twice.
   */
  const debounceOnChange = useMemo(() => {
    return debounce(async (values) => {
      await client.post("me", {
        ready_steady_cook: values,
      });

      setUser(v => ({
        ...v,
        ready_steady_cook: values,
      }));
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUser])

  useOnUpdate(async () => {
    debounceOnChange(values);
  }, [values]);

  return (
    <Page title="Ready, Steady, Cook" className="tw-max-w-3xl">
      <p className="form-text tw-text-sm text-muted">
        Thank you for partnering with TLG! Before you run your first session, be
        sure to indicate you have all of the compliance and safeguarding
        policies in place to ensure a safe and healthy Lunch Club. When your
        Ready Steady Cook icons have turned Green you are ready to run your
        first session of Make Lunch!
      </p>
      <ReadySteadyCookFields />
      {complete ? (
        <Alert color="success">
          <strong>Congratulations!</strong> You are ready to run your first
          session of Make Lunch!
        </Alert>
      ) : null}
    </Page>
  );
};

export const ReadySteadyCook = ({ client }) => {
  const [initialValues, setInitialValues] = useState();

  useOnMount(async () => {
    const { ready_steady_cook } = await client.get("me").get("data");
    setInitialValues(ready_steady_cook);
  });

  if (!initialValues) return null;

  return (
    <Formik initialValues={initialValues}>
      <Form client={client} />
    </Formik>
  );
};

export default ReadySteadyCook;
