import React from "react";
import { Page } from "../../../components/common";
import { useClient } from "../../../hooks";
import { getFormStatus, handleNonFieldErrors } from "../util";
import { useQuery } from "react-query";
import { Nav, NavItem } from "reactstrap";
import { NotificationManager } from "react-notifications";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import { Notes } from "../notes";
import { ApplicationForm, Referral, References } from "./components";
import {ApplicationReferenceForm} from "./components/ApplicationReferenceForm";
import {DBS} from "./components/DBS";
import {
  ApplicationSafeguardingDeclarations
} from "../../safeguarding_declarations/ApplicationSafeguardingDeclarations";

export const ApplicationView = ({ match, location }) => {
  const { id } = match.params;
  const url = `applications/${id}`;
  const client = useClient();
  const { data, refetch } = useQuery([url], () => client.get(url).get("data"));

  if (!data) return null;

  const { form, referral } = data;
  const { status, id: formId } = form;

  async function onFormSubmit(form) {
    try {
      await client.patch(url, {
        form,
      });
      NotificationManager.success("Application updated");
    } catch (ex) {
      handleNonFieldErrors(ex);
    }
    await refetch();
  }

  function getTitle({ form }) {
    const { full_name, role, email } = form;

    return `Application from ${full_name} (${email}) for ${role} role`;
  }

  const title = getTitle(data);

  const formUrl = `${window.location.origin}/application_forms/${formId}`;

  return (
    <Page title={title}>
      <p className="tw--mt-4">
        {getFormStatus(status)}<br/>
        <a href={formUrl} target="_blank" rel="noopener noreferrer">{formUrl}</a>
      </p>
      <Nav tabs className="mb-4">
        <NavItem>
          <NavLink
            replace
            className="nav-link"
            to={`${match.url}/application_form`}
          >
            Application
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink replace className="nav-link" to={`${match.url}/referral`}>
            Referral
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink replace className="nav-link" to={`${match.url}/references`}>
            References
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink replace className="nav-link" to={`${match.url}/notes`}>
            Notes
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink replace className="nav-link" to={`${match.url}/dbs`}>
            DBS
          </NavLink>
        </NavItem>
        <Route path={`${match.url}/reference_form`} render={() => (
          <NavItem>
            <NavLink to={`${match.url}/reference_form`} className="nav-link active">
              Reference form
            </NavLink>
          </NavItem>
        )} />
        <NavItem>
          <NavLink replace className="nav-link" to={`${match.url}/safeguarding_declarations`}>
            Safeguarding Declarations
          </NavLink>
        </NavItem>
      </Nav>
      <Switch>
        <Route path={`${match.url}/application_form`}>
          <ApplicationForm
            key={form}
            defaultValues={form}
            onSubmit={onFormSubmit}
          />
        </Route>
        <Route path={`${match.url}/referral`}>
          <Referral defaultValues={referral} />
        </Route>
        <Route path={`${match.url}/references`}>
          <References match={match} application={id} />
        </Route>
        <Route path={`${match.url}/dbs`}>
          <DBS match={match} application={data} onChanged={refetch} />
        </Route>
        <Route path={`${match.url}/reference_form`} render={({ location }) => (
          <ApplicationReferenceForm defaultValues={location.state || {}} />
        )} />
        <Route path={`${match.url}/notes`}>
          <Notes application={id} />
        </Route>
        <Route path={`${match.url}/safeguarding_declarations`}>
          <ApplicationSafeguardingDeclarations application={id} />
        </Route>
        <Redirect to={`${match.url}/application_form`} />
      </Switch>
    </Page>
  );
};
