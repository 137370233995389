import React, {useState} from "react";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { useMemo } from "react";
import {Button, Col, ModalBody, ModalHeader, Row} from "reactstrap";
import Big from "big.js";
import {useModal} from "../../hooks";
import {RSCBreakdown} from "./RSCBreakdown";

const defaultColors = `#109618
#dc3912`.split("\n");

const Graph = ({ data, colors: colorsProp }) => {
  const colors = colorsProp || defaultColors;

  return (
    <div>
      <ResponsiveContainer minHeight={310}>
        <PieChart>
          <Pie data={data} label dataKey="count">
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
          </Pie>
          <Legend />
          <Tooltip formatter={() => ""} separator="" />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export const ReadySteadyCookGraph = ({ readySteadyCookStats }) => {
  const [activeSection, setActiveSection] = useState(null);
  const { Modal, setIsOpen } = useModal();
  const graphs = useMemo(() => {
    const getSection = (key, title) => {
      const { complete, incomplete } = readySteadyCookStats[key];
      const total = complete + incomplete;
      const completePercentage = new Big(total ? complete / total * 100 : 0).round(1).toString();
      const incompletePercentage = new Big(total ? incomplete / total * 100 : 0).round(1).toString();
      const breakdown = readySteadyCookStats[`${key}_breakdown`];

      return {
        title,
        key,
        breakdown: breakdown ? Object.values(breakdown) : null,
        data: [
          {
            name: `Complete: ${complete} (${completePercentage}%)`,
            count: complete
          },
          {
            name: `Incomplete: ${incomplete} (${incompletePercentage}%)`,
            count: incomplete
          },
        ]
      }
    }

    return [
      getSection('ready', 'READY (Safer recruitment and safeguarding)'),
      getSection('steady', 'STEADY (Compliance)'),
      getSection('cook', 'COOK (Training and preparation)'),
      getSection('all', 'All sections'),
    ]
  }, [readySteadyCookStats]);

  function handleShowBreakdown(section) {
    setActiveSection(section);
    setIsOpen(true);
  }

  return (
    <div>
      {activeSection ? (
        <Modal size="lg">
          <ModalHeader>
            {activeSection.title} breakdown
          </ModalHeader>
          <ModalBody>
            <RSCBreakdown breakdown={activeSection.breakdown} />
          </ModalBody>
        </Modal>
      ) : null}
      <Row form className="tw-gap-y-4">
        {graphs
          .map((i, idx) => (
            <Col key={i.key} lg={6}>
              <div className="tw-flex tw-space-x-2 tw-items-center mb-2">
                <h6 className="mb-0">{i.title}</h6>
                {i.breakdown ? (
                  <Button size="sm" color="link" className="p-0" onClick={() => handleShowBreakdown(i)}>
                    View breakdown
                  </Button>
                ) : null}
              </div>
              <Graph data={i.data} colors={defaultColors} />
            </Col>
          ))}
      </Row>
    </div>
  );
};
