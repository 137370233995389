import { useQuery } from "react-query";
import { useClient } from "../../../hooks";

export const useSafeguardingDeclaration = (url, config) => {
  const client = useClient();
  const queryKey = [url];

  return useQuery({
    queryKey,
    queryFn: () => client.get(...queryKey).get("data"),
    config,
  });
};
