import React from 'react';
import {useUserContext} from "../../hooks";
import {Alert} from "reactstrap";
import {Link} from "react-router-dom";

export function RSCAlert() {
  const { user } = useUserContext();
  const { ready_steady_cook } = user;
  const { ready_complete, steady_complete, cook_complete } = ready_steady_cook;

  if (ready_complete && steady_complete && cook_complete) {
    return null;
  }

  const numIncomplete = (ready_complete ? 0 : 1) + (steady_complete ? 0 : 1) + (cook_complete ? 0 : 1)
  const color = {
    3: 'danger',
    2: 'tlg-orange-500',
    1: 'warning'
  }[numIncomplete];
  const incompleteLabels = [
    ready_complete ? null : 'Ready',
    steady_complete ? null : 'Steady',
    cook_complete ? null : 'Cook',
  ].filter(i => i);

  function getText() {
    if (incompleteLabels.length === 1) {
      return (
        <>
          <strong>One section of your <Link to="/ready_steady_cook">Ready Steady Cook</Link> bar is incomplete.</strong> Please update to confirm you have completed all the necessary steps to ensure your club is safe and effective. Please contact your Church Relationship Lead (CRL) if you have any questions or need any support.
        </>
      )
    } else if (incompleteLabels.length === 2) {
      return (
        <>
          <strong>Two sections of your <Link to="/ready_steady_cook">Ready Steady Cook</Link> are incomplete.</strong> Please update to confirm you have undertaken all the necessary steps to ensure your club is safe and effective. Please contact your Church Relationship Lead (CRL) if you have any questions or need any support.
        </>
      )
    } else {
      return (
        <>
          <strong>No sections of your <Link to="/ready_steady_cook">Ready Steady Cook</Link> bar are complete.</strong> Please update to confirm you have undertaken all the necessary steps to ensure your club is safe and effective. Please contact your Church Relationship Lead (CRL) if you have any questions or need any support.
        </>
      )
    }
  }

  return (
    <Alert color={color}>
      {getText()}
    </Alert>
  )
}