import React from "react";
import { get } from "lodash";
import { Alert } from "reactstrap";
import { useSafeguardingDeclaration } from "./hooks";
import { SafeguardingDeclarationForm } from "./components";
import { useClient } from "../../hooks";
import { Page } from "../../components/common";
import { handleNonFieldErrors } from "../applications/util";

export const SafeguardingDeclarationFormView = ({ match }) => {
  const { id } = match.params;
  const url = `safeguarding_declaration_forms/${id}`;
  const client = useClient();
  const { error, data, refetch } = useSafeguardingDeclaration(url, {
    useErrorBoundary: false,
  });

  const container = (children) => (
    <Page title="ML Core Team Safeguarding Declaration">{children}</Page>
  );

  if (error && get(error, "response.status") === 404) {
    return container(
      <Alert color="warning">
        This Safeguarding Declaration link is not valid.
      </Alert>,
    );
  }

  if (!data) return null;

  async function onSubmit(x) {
    try {
      await client.post(`${url}/submit`, x);
    } catch (ex) {
      handleNonFieldErrors(ex);
    }
    await refetch();
  }

  const { submitted } = data;

  if (submitted) {
    return container(
      <>
        <h3 className="tw-font-medium tw-text-xl tw-mb-4">Thanks!</h3>
        <p>
          Thank you for taking the time to complete this Safeguarding
          Declaration form!
        </p>
        <p>The Make Lunch Team</p>
      </>,
    );
  }

  return container(
    <>
      <SafeguardingDeclarationForm data={data} onSubmit={onSubmit} />
    </>,
  );
};
