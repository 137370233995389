import React from "react";
import { useQuery } from "react-query";
import { Alert, Table } from "reactstrap";
import { NotificationManager } from "react-notifications";
import { ApplicationSafeguardingDeclarationRow } from "./ApplicationSafeguardingDeclarationRow";
import { useClient, useUserContext } from "../../hooks";

export const ApplicationSafeguardingDeclarations = ({ match, application }) => {
  const { isVPSupport } = useUserContext();
  const url = "application_safeguarding_declarations";
  const queryKey = [
    url,
    {
      params: {
        application,
        ordering: "-year",
      },
    },
  ];
  const client = useClient();
  const { data, refetch } = useQuery(queryKey, () =>
    client.get(...queryKey).get("data"),
  );

  async function onSendEmail(i) {
    await client.post(`${url}/${i.id}/send_email`);
    NotificationManager.success("Email sent");
    await refetch();
  }

  if (!data) return null;

  return (
    <div>
      {!data.length ? (
        <Alert color="info">
          No safeguarding declarations for this application
        </Alert>
      ) : (
        <Table size="sm">
          <thead>
            <tr>
              <th>Email</th>
              <th>Year</th>
              <th />
              <th>Status</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {data.map((i) => (
              <ApplicationSafeguardingDeclarationRow
                allowSend={isVPSupport}
                match={match}
                onSendEmail={onSendEmail}
                value={i}
                key={i.id}
              />
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};
