import React, {useMemo} from "react";
import {BulkArchiveButton, FormGroup, Page} from "../../../components/common";
import {useClient, useQueryState, useTable, useUserContext} from "../../../hooks";
import {Table} from "reactstrap";
import {ApplicationRow} from "./components";
import Select from "react-select";
import {useQuery} from "react-query";
import moment from "moment";

function ApplicationStatusFilter({ onChange }) {
  const url = 'applications/statuses';
  const client = useClient();

  const { data: _options } = useQuery({
    queryKey: [url],
    queryFn: () => client.get(url).get('data'),
    config: {
      placeholderData: []
    }
  });

  const options = useMemo(() => {
    return [
      ..._options,
      {
        label: 'Checks completed',
        value: 'checks_completed',
        custom: true
      },
      {
        label: 'No Safeguarding Declaration for this year',
        value: 'no_safeguarding_declaration',
        custom: true
      },
      {
        label: 'Has Safeguarding Declaration for this year',
        value: 'has_safeguarding_declaration',
        custom: true
      }
    ]
  }, [_options])

  return (
    <Select onChange={onChange} className="tw-w-64" options={options} isMulti placeholder="Application filters" />
  )
}

export const ApplicationsList = ({ match }) => {
  const {
    isVPSupport,
  } = useUserContext();
  const url = "applications";
  const [archived, setArchived] = useQueryState("archived", false);
  const client = useClient();
  const {
    TableSearch,
    TableContainer,
    data,
    ViewingRecords,
    Pagination,
    TableDataFetch,
    refreshData,
    selected,
    BulkDeleteButton,
    setFilters,
  } = useTable({
    url: "applications",
    match,
    client,
    filters: {
      archived,
    },
  });

  function onStatusesChange(x) {
    setFilters(y => ({
      ...y,
      checks_completed__isnull: (x || []).find(i => i.value === 'checks_completed') ? false : undefined,
      form__status__in: (x || []).filter(i => !i.custom).map(i => i.value).join(',') || undefined,
      no_safeguarding_declaration_for_year: (x || []).find(i => i.value === 'no_safeguarding_declaration') ? moment().year() : undefined,
      has_safeguarding_declaration_for_year: (x || []).find(i => i.value === 'has_safeguarding_declaration') ? moment().year() : undefined
    }))
  }

  return (
    <Page title="Make Lunch Core Team Applications">
      <TableSearch className="fa-min" />
      <div className="tw-flex tw-mb-3 tw-items-start tw-gap-x-2 tw-flex-wrap">
        <FormGroup label="View Archived" check>
          <input
            type="checkbox"
            className="form-check-input"
            checked={archived}
            onChange={(e) => {
              const { checked } = e.target;
              setArchived(!!checked);
              setFilters((v) => ({
                ...v,
                archived: !!checked,
              }));
            }}
          />
        </FormGroup>
        {isVPSupport ? (
          <>
            <BulkDeleteButton />
            <BulkArchiveButton
              baseUrl={url}
              onSubmitted={() => {
                refreshData();
              }}
              archived={archived}
              selected={selected}
            />
          </>
        ) : null}
        <ApplicationStatusFilter onChange={onStatusesChange} />
      </div>
      <TableContainer>
        <Table size="sm">
          <thead>
            <tr>
              <th select />
              <th sortBy="created">Created</th>
              <th sortBy="form__organisation__organisation_name">Church Partner</th>
              <th sortBy="form__first_name">Applicant</th>
              <th>Application status</th>
              <th>Latest note</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {data.results.map((i) => (
              <tr key={i.id} className={i.checks_completed ? "tw-bg-green-100" : ""}>
                <td select />
                <ApplicationRow allowConvert={isVPSupport} onChanged={refreshData} value={i} match={match} />
              </tr>
            ))}
          </tbody>
        </Table>
      </TableContainer>
      <div className="tw-flex tw-justify-between">
        <ViewingRecords />
        <Pagination />
      </div>
      <TableDataFetch />
    </Page>
  );
};
